export function getFutureDates(inputDate: Date, dayOfWeek: number, weeks: number) {
  const dates = [];

  // @ts-ignore
  const targetDayIndex = dayOfWeek;

  if (targetDayIndex === undefined) {
    throw new Error("Invalid day of the week");
  }

  // Get the current day of the week
  const currentDayIndex = inputDate.getDay();

  // Calculate the number of days to add to get the next target day
  let daysToAdd = targetDayIndex - currentDayIndex;
  if (daysToAdd < 0) {
    daysToAdd += 7;
  }

  for (let i = 0; i < weeks; i++) {
    const nextDate = new Date(inputDate);
    nextDate.setDate(inputDate.getDate() + daysToAdd);
    dates.push(nextDate);
    daysToAdd += 7;
  }

  return dates;
}

export function formatDate(date: Date) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
