import { Booking } from "@/types";
import { axiosInstance } from "../clients";

async function listUserActions(): Promise<{ data: { actions: { bookings: Booking[] } }; meta: { status: 200 } }> {
  return (await axiosInstance.get(`/v1/actions`)).data;
}

async function getSubscriptionBanner(): Promise<{ data: { showSubscriptionBanner: boolean }; meta: { status: 200 } }> {
  return (await axiosInstance.get(`/v1/actions/subscription-banner`)).data;
}

export default {
  listUserActions,
  getSubscriptionBanner,
};
