import { Meta, PaginationMeta } from "@/types";
import { axiosInstance } from "../clients";
import { Organisation } from "@/types/User";

async function listOrganisations(params: {
  page?: string;
  search?: string;
  region?: string;
  sort?: string;
  dir?: string;
}): Promise<{ meta?: PaginationMeta | Meta; data: { organisations: Organisation[] } }> {
  return (await axiosInstance.get(`/v1/organisations`, { withCredentials: true, params })).data;
}

async function toggleTrainerFee(organisation_id: Organisation["id"]): Promise<{ data: { organisation: Organisation } }> {
  return (await axiosInstance.post(`/v1/organisations/${organisation_id}/payment`, { withCredentials: true })).data;
}

export default { listOrganisations, toggleTrainerFee };
