import { EmailList, User } from "@/types";
import { axiosInstance } from "../clients";

async function listEmailLists(): Promise<{ data: { lists: EmailList[] }; meta: { status: 200 } }> {
  return (await axiosInstance.get(`/v1/brevo/lists`)).data;
}

async function getContactInfo(user_id?: User["id"]): Promise<{ data: { lists: number[] }; meta: { status: 200 } }> {
  return (await axiosInstance.get(`/v1/brevo/user`, { params: { user_id: user_id ? user_id : null } })).data;
}

async function toggleUserOnList(id?: EmailList["id"]): Promise<{ data: { message: string }; meta: { status: 200 } }> {
  return (await axiosInstance.post(`/v1/brevo/lists/${id}`)).data;
}

async function setMarketingPreferences(lists: number[], removeLists: number[]): Promise<{ data: { message: string }; meta: { status: 200 } }> {
  return (
    await axiosInstance.post(`/v1/brevo/preferences`, {
      lists,
      removeLists,
    })
  ).data;
}

export default {
  listEmailLists,
  getContactInfo,
  toggleUserOnList,
  setMarketingPreferences,
};
