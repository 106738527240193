import ResourcesList from "@/views/resources/ResourcesList.vue";
import NewResource from "@/views/resources/NewResource.vue";
import ResourcePage from "@/views/resources/ResourcePage.vue";
import EditResource from "@/views/resources/EditResource.vue";

export const resourceRoutes = [
  {
    path: "/dashboard/resources",
    name: "Resources",
    component: ResourcesList,
    meta: {
      name: "Resources",
      breadcrumbs: ["Dashboard", "Resources"],
    },
  },
  {
    path: "/dashboard/resources/new",
    name: "Upload_Resource",
    component: NewResource,
    meta: {
      name: "Upload Resources",
      breadcrumbs: ["Dashboard", "Resources", "Upload_Resource"],
    },
  },
  {
    path: "/dashboard/resources/:id",
    name: "Resource",
    component: ResourcePage,
    meta: {
      name: "Resource",
      breadcrumbs: ["Dashboard", "Resources", "Resource"],
    },
  },
  {
    path: "/dashboard/resources/:id/edit",
    name: "Edit_Resource",
    component: EditResource,
    meta: {
      name: "Edit Resource",
      breadcrumbs: ["Dashboard", "Resources", "Resource", "Edit_Resource"],
    },
  },
];
