export function formatPrice(price: number) {
  if (String(price).includes(".")) {
    return price;
  }

  const newPrice = String(price).substring(0, String(price).length - 2) + "." + String(price).substring(String(price).length - 2);

  return Number(newPrice);
}

export function intlFormat(value: number, currency?: string) {
  return new Intl.NumberFormat("en-GB", { style: "currency", currency: currency ? currency : "GBP" }).format(value);
}
