import { PaginationMeta, Meta, CreateResourceRequest, Resource } from "@/types";
import { axiosInstance } from "../clients";

async function listResources(params: {
  page?: number;
  role?: string;
  language?: string;
  type?: string;
  tag?: string;
}): Promise<{ data: { resources: Resource[]; tags: string[] }; meta: PaginationMeta }> {
  return (await axiosInstance.get(`/v1/resources`, { withCredentials: true, params })).data;
}

async function getResource(id: string): Promise<{ data: { resource: Resource }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/resources/${id}`, { withCredentials: true })).data;
}

async function createResource(data: CreateResourceRequest): Promise<{ data: { resource: Resource }; meta: Meta }> {
  return (await axiosInstance.post("/v1/resources", data, { withCredentials: true })).data;
}

async function updateResource(data: any): Promise<{ data: { resource: Resource }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/resources/${data.resource.id}`, data, { withCredentials: true })).data;
}

async function uploadResource(data: any): Promise<{ data: { id: number; path: string; name: string; size: number }; meta: Meta }> {
  return (
    await axiosInstance.post(`/v1/resources/upload`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  ).data;
}

async function assignAzureId(): Promise<{ data: { id: string }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/resources/assign-id`, { withCredentials: true })).data;
}

async function deleteResource(id: number): Promise<{ data: { deleted: boolean }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/resources/${id}`, { withCredentials: true })).data;
}

async function deleteFile(id: number): Promise<{ data: { deleted: boolean }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/files/${id}`, { withCredentials: true })).data;
}

export default {
  listResources,
  getResource,
  createResource,
  updateResource,
  deleteResource,
  uploadResource,
  deleteFile,
  assignAzureId
};
