import IndexView from "@/views/IndexView.vue";
import LoginView from "@/views/LoginView.vue";
import SignupView from "@/views/SignupView.vue";
import SignupRedirect from "@/views/SignupRedirect.vue";
import RedirectView from "@/views/RedirectView.vue";

import AccountDisabled from "@/views/AccountDisabled.vue";

export const authRoutes = [
  {
    path: "/",
    name: "Welcome",
    component: IndexView,
  },
  {
    path: "/account-disabled",
    name: "Account Disabled",
    component: AccountDisabled,
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignupView,
  },
  {
    path: "/redirect",
    name: "Redirect",
    component: RedirectView,
  },
  {
    path: "/signup/redirect",
    name: "Redirecting",
    component: SignupRedirect,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: {
      blockAuth: true,
    },
  },
];
