import OrganisationList from "@/components/OrganisationList.vue";

import UsersList from "@/views/users/UsersList.vue";
import NewUser from "@/views/users/NewUser.vue";

import UserGroups from "@/views/users/UserGroups.vue";
import UserGroup from "@/views/users/UserGroup.vue";

import UserProfile from "@/views/users/UserProfile.vue";

export const userRoutes = [
  {
    path: "/dashboard/organisations",
    name: "Organisations",
    component: OrganisationList,
    meta: {
      name: "Organisations",
      breadcrumbs: ["Dashboard", "Organisations"],
    },
  },
  {
    path: "/dashboard/users",
    name: "Users",

    component: UsersList,
    meta: {
      name: "Users",
      breadcrumbs: ["Dashboard", "Users"],
    },
  },
  {
    path: "/dashboard/users/new",
    name: "Add_a_new_User",

    component: NewUser,
    meta: {
      name: "Add a new User",
      breadcrumbs: ["Dashboard", "Users", "Add_a_new_User"],
    },
  },
  {
    path: "/dashboard/users/:id",
    name: "User_Profile",

    component: UserProfile,
    meta: {
      name: "User Profile",
      breadcrumbs: ["Dashboard", "Users", "User_Profile"],
    },
  },
  {
    path: "/dashboard/user-groups",
    name: "User_Groups",
    component: UserGroups,
    meta: {
      name: "User Groups",
      breadcrumbs: ["Dashboard", "User_Groups"],
    },
  },
  {
    path: "/dashboard/user-groups/:role",
    name: "User_Group",
    component: UserGroup,
    meta: {
      name: "User Group",
      breadcrumbs: ["Dashboard", "User_Groups", "User_Group"],
    },
  },
];
