import ClubsList from "@/views/clubs/ClubsList.vue";
import NewClubVue from "@/views/clubs/NewClub.vue";
import EditClubPageVue from "@/views/clubs/EditClubPage.vue";
import ClubPageVue from "@/views/clubs/ClubPage.vue";

import NewStudent from "@/views/students/NewStudent.vue";
import StudentPage from "@/views/students/StudentPage.vue";
import EditStudent from "@/views/students/EditStudent.vue";

export const clubRoutes = [
  {
    path: "/dashboard/clubs",
    name: "Brick_Clubs",
    component: ClubsList,
    meta: {
      name: "Brick Clubs",
      breadcrumbs: ["Dashboard", "Brick_Clubs"],
    },
  },
  {
    path: "/dashboard/clubs/new",
    name: "Add_Brick_Club",
    component: NewClubVue,
    meta: {
      name: "Add Brick Club",
      breadcrumbs: ["Dashboard", "Brick_Clubs", "Add_Brick_Club"],
    },
  },
  {
    path: "/dashboard/clubs/:id",
    name: "Brick_Club",
    component: ClubPageVue,
    meta: {
      name: "Brick Club",
      breadcrumbs: ["Dashboard", "Brick_Clubs", "Brick_Club"],
    },
  },
  {
    path: "/dashboard/clubs/:id/edit",
    name: "Edit_Brick_Club",
    component: EditClubPageVue,
    meta: {
      name: "Edit Brick Club",
      breadcrumbs: ["Dashboard", "Brick_Clubs", "Brick_Club", "Edit_Brick_Club"],
    },
  },
  {
    path: "/dashboard/clubs/:id/participants/new",
    name: "Add_Club_Participant",
    component: NewStudent,
    meta: {
      name: "Add Club Participant",
      breadcrumbs: ["Dashboard", "Brick_Clubs", "Brick_Club", "Add_Club_Participant"],
    },
  },
  {
    path: "/dashboard/clubs/:id/participants/:student_id",
    name: "View_Club_Participant",
    component: StudentPage,
    meta: {
      name: "View Club Participant",
      breadcrumbs: ["Dashboard", "Brick_Clubs", "Brick_Club", "View_Club_Participant"],
    },
  },
  {
    path: "/dashboard/clubs/:id/participants/:student_id/edit",
    name: "Edit_Club_Participant",
    component: EditStudent,
    meta: {
      name: "Edit Club Participant",
      breadcrumbs: ["Dashboard", "Brick_Clubs", "Brick_Club", "View_Club_Participant", "Edit_Club_Participant"],
    },
  },
];
