import { Attendance, Club, CreateStudentNoteRequest, Student, StudentNote, User } from "@/types";
import { axiosInstance } from "../clients";

async function listMembersByOrganisation() {
  return (await axiosInstance.get(`/v1/members`, { withCredentials: true })).data;
}

async function getMemberByOrganisation(member_id: Student["id"]) {
  return (await axiosInstance.get(`/v1/members/${member_id}`, { withCredentials: true })).data;
}

async function listMembersByFacilitator(facilitator_id: User["id"]) {
  return (await axiosInstance.get(`/v1/facilitators/members?facilitator_id=${facilitator_id}`, { withCredentials: true })).data;
}

async function getMemberByFacilitator(facilitator_id: User["id"], member_id: Student["id"]) {
  return (await axiosInstance.get(`/v1/facilitators/members/${member_id}?facilitator_id=${facilitator_id}`, { withCredentials: true })).data;
}

/** Club Notes */
async function listStudentNotes(club_id: Club["id"], student_id: Student["id"]) {
  return (await axiosInstance.get(`/v1/clubs/${club_id}/students/${student_id}/notes`, { withCredentials: true })).data;
}

async function createStudentNote(club_id: Club["id"], student_id: Student["id"], data: CreateStudentNoteRequest) {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students/${student_id}/notes`, data, { withCredentials: true })).data;
}

async function updateStudentNote(club_id: Club["id"], student_id: Student["id"], id: StudentNote["id"], data: CreateStudentNoteRequest) {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students/${student_id}/notes/${id}`, data, { withCredentials: true })).data;
}

async function deleteStudentNote(club_id: Club["id"], student_id: Student["id"], id: StudentNote["id"]) {
  return (await axiosInstance.delete(`/v1/clubs/${club_id}/students/${student_id}/notes/${id}`, { withCredentials: true })).data;
}

async function listStudentsByParent(parent_id: User["id"], club_id?: number): Promise<{ meta: { status: 200 }; data: { students: Student[] } }> {
  const params = club_id ? { club_id } : {};

  return (await axiosInstance.get(`/v1/parent/${parent_id}/students`, { withCredentials: true, params })).data;
}

async function getStudentByParent(parent_id: User["id"], student_id: Student["id"]): Promise<{ meta: { status: 200 }; data: { student: Student } }> {
  return (await axiosInstance.get(`/v1/parent/${parent_id}/students/${student_id}`, { withCredentials: true })).data;
}

async function updateStudentByParent(parent_id: User["id"], student_id: Student["id"], data: any): Promise<{ meta: { status: 200 }; data: { student: Student } }> {
  return (await axiosInstance.post(`/v1/parent/${parent_id}/students/${student_id}`, data, { withCredentials: true })).data;
}

async function deleteStudentByParent(parent_id: User["id"], student_id: Student["id"]): Promise<{ meta: { status: 200 }; data: { student: Student } }> {
  return (await axiosInstance.delete(`/v1/parent/${parent_id}/students/${student_id}`, { withCredentials: true })).data;
}

async function listParentStudentClubs(parent_id: User["id"]): Promise<{ meta: { status: 200 }; data: { clubs: Club[] } }> {
  return (await axiosInstance.get(`/v1/parent/${parent_id}/clubs`, { withCredentials: true })).data;
}

async function listParentStudentNotes(parent_id: User["id"], student_id: Student["id"]): Promise<{ meta: { status: 200 }; data: { notes: StudentNote[] } }> {
  return (await axiosInstance.get(`/v1/parent/${parent_id}/students/${student_id}/notes`, { withCredentials: true })).data;
}

async function listParentStudentGoals(parent_id: User["id"], student_id: Student["id"]): Promise<{ meta: { status: 200 }; data: { goals: any[] } }> {
  return (await axiosInstance.get(`/v1/parent/${parent_id}/students/${student_id}/goals`, { withCredentials: true })).data;
}

async function listParentStudentsAttenance(parent_id: User["id"], club_id: Club["id"]): Promise<{ meta: { status: 200 }; data: { attendance: Attendance[] } }> {
  return (await axiosInstance.get(`/v1/parent/${parent_id}/students/attendance?club_id=${club_id}`, { withCredentials: true })).data;
}

async function checkParentEmailsByParent(
  parent_id: User["id"],
  student_id: Student["id"],
  emails: string[]
): Promise<{
  meta: { status: 200 };
  data: {
    email: string;
    valid: boolean;
    message: string;
  }[];
}> {
  return (await axiosInstance.post(`/v1/parent/${parent_id}/students/${student_id}/parent-email-check`, { emails }, { withCredentials: true })).data;
}

async function checkParentEmailsByFacilitator(
  club_id: string,
  emails: string[]
): Promise<{
  meta: { status: 200 };
  data: {
    email: string;
    valid: boolean;
    message: string;
  }[];
}> {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/parent-email-check`, { emails }, { withCredentials: true })).data;
}

export default {
  listMembersByOrganisation,
  getMemberByOrganisation,
  listStudentNotes,
  createStudentNote,
  updateStudentNote,
  deleteStudentNote,
  listStudentsByParent,
  getStudentByParent,
  updateStudentByParent,
  listParentStudentClubs,
  deleteStudentByParent,
  listParentStudentNotes,
  listMembersByFacilitator,
  getMemberByFacilitator,
  listParentStudentGoals,
  listParentStudentsAttenance,
  checkParentEmailsByParent,
  checkParentEmailsByFacilitator,
};
