import { DetailedResults, Meta, Survey, SurveyResponse } from "@/types";
import { axiosInstance } from "../clients";

/** Surveys */
async function listSurveys(params: { type?: string; query?: string; status?: string; sort?: string; dir?: string }): Promise<{ meta: Meta; data: { surveys: Survey[] } }> {
  return (
    await axiosInstance.get(`/v1/surveys`, {
      withCredentials: true,
      params,
    })
  ).data;
}

async function getSurvey(survey_id: Survey["id"]): Promise<{ meta: Meta; data: { survey: Survey } }> {
  return (await axiosInstance.get(`/v1/surveys/${survey_id}`, { withCredentials: true })).data;
}

async function updateSurvey(survey_id: Survey["id"], survey: Survey) {
  return (await axiosInstance.post(`/v1/surveys/${survey_id}`, { ...survey }, { withCredentials: true })).data;
}

async function listSurveyResponses(survey_id: Survey["id"]): Promise<{ meta: Meta; data: { responses: SurveyResponse[] } }> {
  return (await axiosInstance.get(`/v1/surveys/${survey_id}/responses/`, { withCredentials: true })).data;
}

async function getSurveyResultsTotal(survey_id: Survey["id"]): Promise<{ meta: Meta; data: { results: DetailedResults[] } }> {
  return (await axiosInstance.get(`/v1/surveys/${survey_id}/results`, { withCredentials: true })).data;
}

async function getSurveyResultsByEvent(survey_id: Survey["id"], event_id: string): Promise<{ meta: Meta; data: { results: DetailedResults[] } }> {
  return (await axiosInstance.get(`/v1/surveys/${survey_id}/results/${event_id}`, { withCredentials: true })).data;
}

async function getCourseSurveys(ids: number[]): Promise<{ meta: Meta; data: { surveys: { [key: string]: string } } }> {
  return (await axiosInstance.post(`/v1/surveys/events`, { ids }, { withCredentials: true })).data;
}

async function getCourseSurveyLinks(ids: number[]): Promise<{ meta: Meta; data: { urls: { [key: string]: string } } }> {
  return (await axiosInstance.post(`/v1/surveys/survey-links`, { ids }, { withCredentials: true })).data;
}

export default {
  listSurveys,
  getSurvey,
  updateSurvey,
  listSurveyResponses,
  getSurveyResultsTotal,
  getSurveyResultsByEvent,
  getCourseSurveys,
  getCourseSurveyLinks,
};
