import DiscountsList from "@/views/discounts/DiscountsList.vue";
import NewDiscount from "@/views/discounts/NewDiscount.vue";
import DiscountPage from "@/views/discounts/DiscountPage.vue";
import EditDiscount from "@/views/discounts/EditDiscount.vue";

export const discountRoutes = [
  {
    path: "/dashboard/courses/discounts",
    name: "Discounts",
    component: DiscountsList,
    meta: {
      name: "Discounts",
      breadcrumbs: ["Dashboard", "Courses", "Discounts"],
    },
  },
  {
    path: "/dashboard/courses/discounts/new",
    name: "add_a_new_discount",
    component: NewDiscount,
    meta: {
      name: "Add a New Discount",
      breadcrumbs: ["Dashboard", "Courses", "Discounts", "add_a_new_discount"],
    },
  },
  {
    path: "/dashboard/courses/discounts/:id",
    name: "Discount",
    component: DiscountPage,
    meta: {
      name: "Discount",
      breadcrumbs: ["Dashboard", "Courses", "Discounts", "Discount"],
    },
  },
  {
    path: "/dashboard/courses/discounts/:id/edit",
    name: "Edit_Discount",
    component: EditDiscount,
    meta: {
      name: "Edit Discount",
      breadcrumbs: ["Dashboard", "Courses", "Discounts", "Discount", "Edit_Discount"],
    },
  },
];
