import { PaginationMeta, Meta, Discount, CreateDiscountRequest } from "@/types";
import { axiosInstance } from "../clients";

async function listDiscounts(params: {
  page: number;
  query?: string;
  merchant?: string;
  sort?: string;
  dir?: string;
}): Promise<{ data: { discounts: Discount[] }; meta: PaginationMeta }> {
  return (await axiosInstance.get(`/v1/discounts`, { withCredentials: true, params })).data;
}

async function getDiscount(id: string): Promise<{ data: { discount: Discount }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/discounts/${id}`, { withCredentials: true })).data;
}

async function createDiscount(data: CreateDiscountRequest): Promise<{ data: { discount: Discount; meta: Meta } }> {
  return (await axiosInstance.post("/v1/discounts", data, { withCredentials: true })).data;
}

async function updateDiscount(id: number, data: any): Promise<{ data: { discount: Discount }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/discounts/${id}`, data, { withCredentials: true })).data;
}

async function deleteDiscount(id: number): Promise<{ data: { deleted: boolean }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/discounts/${id}`, { withCredentials: true })).data;
}

export default {
  listDiscounts,
  getDiscount,
  createDiscount,
  updateDiscount,
  deleteDiscount,
};
